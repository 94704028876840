import React from 'react';
import Facebook from '../assets/images/Blog_icon_reseau_facebook.png';
import Twitter from '../assets/images/Blog_icon_reseau_twitter.png';
// import LinkedIn from '../assets/images/Blog_icon_reseau_linkedin.png';

export default (props) => {
  const facebookShare = "https://www.facebook.com/sharer/sharer.php?u=https%3A//www.wakatoon.com";
  const twitterShare = "https://twitter.com/intent/tweet?text=https%3A//www.wakatoon.com";
  // const linkedinShare = "https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.wakatoon.com";
  return (
    <div>
      <a href={`${facebookShare}${props.link}`} target="_blank" rel="noopener noreferrer">
        <img src={Facebook} alt="Facebook" />
      </a>
      <a href={`${twitterShare}${props.link}`} target="_blank" rel="noopener noreferrer">
        <img src={Twitter} alt="Twitter" />
      </a>
      {/* <a href={`${linkedinShare}${props.link}&title=${props.title}&summary=&source=`} target="_blank" rel="noopener noreferrer">
        <img src={LinkedIn} alt="LinkedIn" />
      </a> */}
    </div>
  )
}