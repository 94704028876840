import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/Index'
import SEO from '../components/SEO'
import SocialBar from '../components/SocialBar';
import Style from '../styles/components/post.module.scss'
import BlogStyle from '../styles/pages/blog.module.scss'
import BlogPosts from '../components/Banners/BlogPosts';
import PlaceIcon from '../assets/images/Blog_icon_evenement_lieu.png';
import DateIcon from '../assets/images/Blog_icon_evenement_date.png';

export default ({
  pageContext,
  data: { prismicBlogPost: { uid, data }, blogPosts, staticText}
}) => {
  const staticPrismicTexts = staticText.edges[0].node.data.texts;

  return (
    <Layout 
      context={pageContext} 
      pageTitle={data.meta_title}
      _t={staticPrismicTexts}
    >
      <SEO 
        pageTitle={data.meta_title} 
        pageDescription={data.meta_description} 
        context={pageContext} 
        ogTitle={data.meta_title} 
        ogImage={data.thumbnail.url}
        ogUrl={`https://www.wakatoon.com${pageContext.slug}`}
      />
      <div className={`${Style.blogPostPage} page`}>
        <div className={BlogStyle.hero}>
          {
            (((JSON.parse(data.is_event__) === false || Date.now() > new Date(data.date)) && data.header.url)) && (
              <img src={data.header.url} alt={data.meta_title} />
            )
          }
        </div>
        <section style={{ background: "white", paddingBottom: 100 }}>
          <div className="container">
            <div className={`breadcrumb ${Style.breadcrumb}`}>
              <Link to="/fr/">Wakatoon</Link>
              &nbsp;/&nbsp;<Link to={`/${pageContext.langKey}/blog/`}>Blog</Link>
            </div>
          </div>
          <article>
            {
              (JSON.parse(data.is_event__) && Date.now() < new Date(data.date)) && (
                <div className={Style.eventHeaderContainer}>
                  <img src={data.article_header.url} alt={data.meta_title} />
                </div>
              )
            }
            <h1>{data.title.text}</h1>
            <p className={Style.category}>
              <span style={{ color: `${data.category.document[0].data.color}` }}>
                {data.category.document[0].data.category_name}
              </span>
            </p>
            <div className={Style.socialBar}>
              <SocialBar link={pageContext.slug} title={data.title.text} />
            </div>

            {
              (JSON.parse(data.is_event__) && Date.now() < new Date(data.date)) && (
                <React.Fragment>
                  <div className={Style.eventInfosContainer}>
                    <ul>
                      <li>
                        <i><img src={DateIcon} alt="Date" /></i>
                        <span><strong>{data.date_text.text}</strong></span>
                      </li>
                      <li
                        style={{cursor:'pointer'}} 
                        onClick={() => typeof window !== 'undefined' && window.open(data.google_places_link.url, '_blank')}
                      >
                        <i><img src={PlaceIcon} alt="Lieu" /></i>
                        <span dangerouslySetInnerHTML={{ __html: data.address.html }}></span>
                      </li>
                    </ul>
                  </div>
                  <h3 style={{ color: `${data.category.document[0].data.color}` }} >
                    {data.subtitle.text}
                  </h3>
                </React.Fragment>
              )
            }

            <div className={Style.content} dangerouslySetInnerHTML={{ __html: data.content.html }}></div>
          </article>
        </section>
        <BlogPosts post postId={uid} {...blogPosts} />
      </div>
    </Layout>
  );
};


export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicBlogPost(uid: { eq: $uid }, lang: {eq: $locale}) {
      lang
      uid
      data {
        date
        address {
          html
        }
        article_header {
          url
        }
        category {
          uid
          document {
            data {
              category_name
              color
            }
          }
        }
        google_places_link {
          url
        }
        content {
          html
        }
        thumbnail {
          url
        }
        date_text {
          text
        }
        is_event__
        subtitle {
          text
        }
        title {
          text
        }
        header {
          url
        }
        meta_title
        meta_description
      }
    }
    blogPosts: allPrismicBlogPost {
      edges { ...blogPosts }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;